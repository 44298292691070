<template>
  <v-container
    v-if="products"
    class="product-recall-container"
    @click="clicked"
  >
    <h1>Richiamo prodotti</h1>
    <v-alert class="warning-alert text-center" color="primary">
      <h3 class="white--text">ATTENZIONE!</h3>
      <p class="white--text font-weight-bold">
        Qualora aveste acquistato uno di questi prodotti, con il numero di
        lotto/scadenza indicati, vi invitiamo cortesemente a riportarlo presso
        il punto vendita d'acquisto o a seguire le indicazioni riportate.
      </p>
    </v-alert>
    <v-row
      class="product-recall-row ma-1 mb-6 grey lighten-1 rounded-lg rounded-bl-0 "
      v-for="product in products"
      :key="product.id"
    >
      <v-col col="12" sm="3" class="pa-6 rounded-tl-lg white"
        ><v-img :src="product.featured_image_url"></v-img
      ></v-col>
      <v-col col="12" sm="9" class="pa-6">
        <h3 class="primary--text">{{ product.title.rendered }}</h3>
        <p v-html="product.content.rendered"></p>
        <v-btn
          v-if="product.file_pdf"
          large
          elevation="0"
          :href="product.file_pdf.guid"
          target="_system"
          class="primary"
          >Scarica l'avviso di richiamo</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.product-recall-container {
  .product-recall-row {
    border: 1px solid var(--v-grey-base) !important;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import clickHandler from "~/mixins/clickHandler";
import CMService from "~/service/cmService";

export default {
  name: "ProductRecall",
  mixins: [clickHandler],
  components: {},

  data() {
    return {
      pager: {},
      products: null
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    async fetchProducts(pageNum) {
      let params = [];
      this.page = pageNum || this.pageFilter;
      this.pager.selPage = this.page;
      params.push({
        filterName: "page",
        filterValue: this.page
      });

      const res = await CMService.getCustomPostByFilters(
        "richiamo_prodotti",
        params,
        12
      );
      let products = null;
      if (res && res.data && res.data.length) {
        this.pager = res.page;
        products = res.data;
      }
      return products;
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      this.fetchProducts(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  async mounted() {
    this.products = await this.fetchProducts();
  }
};
</script>
